import {defineStore} from "pinia";
// import Cookies from 'js-cookie';
import axios from "@/plugins/axios";

export const useUserStore = defineStore("UserStore", {

    state: () => ({
        user: null,
        company: null,
        companies: [],
        isLogin: false,
        profile: null,
        navBarState: {permanentExtend: true, miniState: true, navBarIsOpen: true},
        lastTabPath: null
    }),

    actions: {

        async fetchNewCompany() {
            const {data} = await axios.get("companies/companies/me_join/")
            console.log(data)
            this.companies = data
        },

        async fetchUserProfile() {
            if (this.company) {
                let {data} = await axios.get(`accounts/profile/?company=${this.company.id}`)
                this.profile = data
                this.user = data
            }
        },

        async fetchUserCompanies() {
            const {data} = await axios.get("companies/companies/me_join/")
            console.log(data)
            this.company = data[0]

        },

        setNewData(data) {
            this.user = data
        },

        setNewAvatar(data) {
            this.user.avatar = data.avatar
        },

        async setUser(data) {
            localStorage.setItem('token', data.token)
            this.user = data.user
            await this.fetchUserCompanies()
            await this.fetchUserProfile()
            this.isLogin = true
        },

        logout() {
            this.router.push("/login")
            localStorage.removeItem('token')
            this.isLogin = false
            this.profile = null
            this.user = null
            this.company = null
            localStorage.clear()
        },

        setUserCompany(company) {
            this.company = company
        },

        changeNavBarState() {
            this.navBarState.permanentExtend = !this.navBarState.permanentExtend
        },

        changeOpenNavbar() {
            this.navBarState.navBarIsOpen = !this.navBarState.navBarIsOpen
        },

        changeMiniState(data) {
            this.navBarState.miniState = data
        },

        setLastTabPath(path) {
            this.lastTabPath = path
        }
    },

    getters: {
        userState: (state) => state.user,
        getCompany: (state) => state.company,
        getLoginStatus: (state) => state.isLogin,
        getUserProfile: (state) => state.profile,
        getNavBarState: (state) => state.navBarState,
        getLastPathTab: (state) => state.lastTabPath
    },

    persist: true,

})