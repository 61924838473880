import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {useUserStore} from "@/stores/UserStore";

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/Login'),
        meta: {
            need_layout: false
        }
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: () => import('../views/forgotPassword/ForgotPassword'),

    },

    {
        path: "/",
        component: () => import("../layouts/MainLayout"),
        children: [
            {
                path: '/',
                name: 'home',
                component: HomeView,
            },
            {
                path: '/tasks',
                name: 'tasks',
                component: () => import('../views/tasks/Tasks'),
            },
            {
                path: '/control',
                name: 'control',
                component: () => import('../views/control/Control'),
            },
            {
                path: '/employees',
                name: 'employees',
                component: () => import('../views/employees/Employees'),
            },
            {
                path: '/positions',
                name: 'positions',
                component: () => import('../views/positions/Positions'),
            },
            {
                path: '/users',
                name: 'users',
                component: () => import('../views/users/Users'),
            },
            {
                path: '/companies',
                name: 'companies',
                component: () => import('../views/companies/Companies'),
            },
            {
                path: '/admins',
                name: 'admins',
                component: () => import('../views/admins/Admin'),
            },
            {
                path: '/test',
                name: 'test',
                component: () => import('../views/test/TestPage'),
            },
        ]
    },
    {path: '/:pathMatch(.*)*', component: () => import('../views/Page404.vue')},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to) => {
    const token = localStorage.getItem('token') ? "Bearer " + localStorage.getItem('token') : null
    if (
        !token &&
        to.name !== 'login' && to.name !== 'forgotPassword'
    ) {
        return {name: 'login'}
    }
    const userStore = useUserStore()
    userStore.setLastTabPath(to.path)
})

export default router
