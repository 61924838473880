<template>
  <q-layout>
    <q-page-container>
      <router-view/>
    </q-page-container>
  </q-layout>
</template>


<script>

export default {
  name: 'LayoutDefault',
}
</script>

<style>

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body {
  background: #F2F6F8;
  color: #333333 !important;
}

.dashed-cell {
  color: #333333 !important;
}

input {
  color: #333333 !important;
}

</style>

<style lang="scss">
.q-table thead :deep(thead .q-tr) {
  height: 51px !important;
}

.q-table :deep(.cursor-pointer) {
  height: 30px !important;
}

.q-table tbody :deep(.cursor-pointer .q-td) {
  height: 30px !important;
}

.q-table th, .q-table td {
  padding: 5px 16px;
  background-color: inherit;
  height: 10px !important;
}


.q-field__inner :deep(span) {
  color: #333333 !important;
}
</style>
// const isLoginPage = computed(() => {
//   if (route.name === "login") {
//     return true
//   } else {
//     return false
//   }
// });

// onMounted(() => {
//   console.log(route.name)
// })