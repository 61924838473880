import axios from "axios";
// import Cookies from "js-cookie";
import {useUserStore} from "@/stores/UserStore";

const axiosInstance = axios.create({
    baseURL: "http://77.232.132.58:8003/",
   // baseURL: "http://dev1.itpw.ru:8003/",
    headers: {
        // 'Authorization': localStorage.getItem('token') ? "Bearer " + localStorage.getItem('token') : null,
        'Authorization': localStorage.getItem('token') ? "Bearer " + localStorage.getItem('token') : '',
    }
})

axiosInstance.interceptors.request.use(function (config) {
    let token = localStorage.getItem("token")
    config.headers.Authorization = token ? "Bearer " + token : null
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response.status === 400) {
            return error.response
        }
        if (error.response.status === 401) {
            const userStore = useUserStore()
            userStore.logout()
        }
        if (error.response.status === 403) {
            const userStore = useUserStore()
            userStore.fetchUserCompanies()
            userStore.fetchNewCompany()
            return error.response
        } else {
            return error
        }
    }
)

export default axiosInstance
