// eslint-disable-next-line no-unused-vars
import {createApp, markRaw, watch} from 'vue'
import {createPinia} from 'pinia'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

let pinia = createPinia()

if (localStorage.getItem("state")) {
    pinia.state.value = JSON.parse(localStorage.getItem("state"))
}

pinia.use(piniaPluginPersistedstate)

pinia.use(({store}) => {
    store.router = markRaw(router)
});


createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(pinia)
    .use(router)
    .use(VCalendar, {})
    .mount('#app')
